import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import urls from 'src/constants/api'
import { store } from 'src/store'

export function registrationApi(): AxiosInstance {
  const registrationApi = axios.create({
    baseURL: urls.registrationAPI
  })

  registrationApi.interceptors.request.use(
    async (request: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
      request.headers.Authorization = `Bearer ${
        store.getState().userReducer.auth.accessToken
      }`
      request.headers['subscription-key'] = urls.apimSubscriptionKey
      return request
    }
  )

  return registrationApi
}

export function workflowAPI(): AxiosInstance {
  const workflowAPI = axios.create({ baseURL: urls.workflowAPI })

  workflowAPI.interceptors.request.use(
    async (request: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
      request.headers.Authorization = `Bearer ${
        store.getState().userReducer.auth.accessToken
      }`
      request.headers['subscription-key'] = urls.apimSubscriptionKey
      return request
    }
  )

  return workflowAPI
}

export function filesInformationAPI(): AxiosInstance {
  const filesInformationAPI = axios.create({
    baseURL: urls.filesInformationAPI
  })

  filesInformationAPI.interceptors.request.use(
    async (request: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
      request.headers.Authorization = `Bearer ${
        store.getState().userReducer.auth.accessToken
      }`
      request.headers['subscription-key'] = urls.apimSubscriptionKey
      return request
    }
  )
  return filesInformationAPI
}

export function authApi(): AxiosInstance {
  const authApi = axios.create({ baseURL: urls.authAPI })

  authApi.interceptors.request.use(
    async (request: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
      request.headers['subscription-key'] = urls.apimSubscriptionKey
      return request
    }
  )

  return authApi
}
