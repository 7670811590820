import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { MassRegistrationOrigin } from 'src/constants'
import { store } from 'src/store'
import { convertBase64 } from 'src/utils/convertBase64'
import urls from '../../constants/api'

interface IApi {
  fileImporterApi: AxiosInstance
  uploadCsv(
    file: File,
    token: string,
    originValue: MassRegistrationOrigin
  ): Promise<AxiosResponse>
  getCsvTemplate(token: string): Promise<AxiosResponse>
}

export default (): IApi => {
  const fileImporterApi = axios.create({
    baseURL: urls.fileImporter
  })

  fileImporterApi.interceptors.request.use(
    async (request: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
      request.headers.Authorization = `Bearer ${
        store.getState().userReducer.auth.accessToken
      }`
      request.headers['subscription-key'] = urls.apimSubscriptionKey
      return request
    }
  )

  return {
    fileImporterApi,

    uploadCsv: async (
      file: File,
      token: string,
      originValue: MassRegistrationOrigin
    ) => {
      // const formData = new FormData()
      // formData.append('File', file)
      // formData.append('Origin', originValue.toString())
      const headers = {
        'X-Authorization': `${token}`,
        'subscription-key': urls.apimSubscriptionKey
        // 'Content-Type': 'multipart/form-data'
      }

      const fileBase64 = (await convertBase64(file)) as string

      const formData = {
        fileName: file.name,
        length: file.size,
        contentType: file.type,
        fileBase64: fileBase64.split(',')[1],
        Origin: originValue.toString()
      }

      return fileImporterApi.post(
        `/FileImporter/csv-mass-registration`,
        formData,
        {
          headers
        }
      )
    },

    getCsvTemplate: (token: string) => {
      const headers = {
        'X-Authorization': `${token}`,
        'subscription-key': urls.apimSubscriptionKey
      }
      return fileImporterApi.get(
        `/FileImporter/file-template-download?templateKey=template_primeiro_acesso`,
        {
          headers
        }
      )
    }
  }
}
