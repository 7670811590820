import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import api from 'src/constants/api'
import { store } from 'src/store'
import urls from 'src/constants/api'

interface IApi {
  registrationApi: AxiosInstance
  saveAdditionalInfo(data: any, token: string): Promise<AxiosResponse>
  getAdditionalInfo(cpf: string, token: string): Promise<AxiosResponse>
  updateAdditionalInfo(data: any, token: string): Promise<AxiosResponse>
}

export default (): IApi => {
  const registrationApi = axios.create({
    baseURL: api.registrationAPI
  })

  registrationApi.interceptors.request.use(
    async (request: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
      request.headers.Authorization = `Bearer ${
        store.getState().userReducer.auth.accessToken
      }`
      request.headers['subscription-key'] = urls.apimSubscriptionKey
      return request
    }
  )

  return {
    registrationApi: registrationApi,

    saveAdditionalInfo: (data: any, token: string): Promise<AxiosResponse> => {
      const headers = {
        'X-Authorization': `${token}`,
        'subscription-key': urls.apimSubscriptionKey
      }
      return registrationApi.post(
        `/Registration/additional-information`,
        data,
        {
          headers
        }
      )
    },

    getAdditionalInfo: (cpf: string, token: string): Promise<AxiosResponse> => {
      const headers = {
        'X-Authorization': `${token}`,
        'subscription-key': urls.apimSubscriptionKey
      }
      return registrationApi.get(
        `/Registration/additional-information?cpf=${cpf}`,
        {
          headers
        }
      )
    },
    updateAdditionalInfo: (
      data: any,
      token: string
    ): Promise<AxiosResponse> => {
      const headers = {
        'X-Authorization': `${token}`,
        'subscription-key': urls.apimSubscriptionKey
      }
      return registrationApi.put('Registration/additional-information', data, {
        headers
      })
    }
  }
}
